.filterDetailPage {
  padding: 40px;
  .form {
    padding: 40px;
    max-width: 650px;
    background: #fff;
    transition: border-color 0.15s cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    .title {
      margin-bottom: 40px;
    }

    .horizontal {
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 48%;
        margin-right: 2%;
      }
    }

    .actions {
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      max-width: 650px;

      .downloadButton {
        margin-right: 10px;
      }

      & > div {
        display: flex;
      }
    }
  }
}

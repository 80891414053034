.filterDetailPage {
  .form {
    padding: 40px;

    .title {
      margin-bottom: 40px;
    }

    .actions {
      padding-top: 30px;
      display: flex;
      justify-content: flex-end;
      max-width: 500px;
    }
  }
}
.contactItemForm {
  border-radius: 4px;
  background: #ffffff;
  margin-bottom: 12px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  .horizontal {
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 50%;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
}

.hashTag {
  display: flex;
  font-size: 12px;
  background: #0f1011;
  color: #fff;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: pointer;

  &:hover {
    background: #404040;
  }
}
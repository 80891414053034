.calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    color: #868e96;
    margin-top: 14px;
  }

  .input {
    background: #ffffff;
    box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    min-width: 300px;
    max-width: 500px;
    height: 42px;
    cursor: pointer;
    transition: .15s box-shadow ease-in-out;

    &:hover {
      box-shadow: inset 0 0 0 1px #396eff, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    .icon {
      width: 42px;
      height: 42px;
      background-size: 100%;
      right: 0;
      position: absolute;

      &.isModify {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #00e7ff;
        width: 70px;
      }
    }

    .placeholder {
      align-items: center;
      display: flex;
      height: 100%;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #efefef;
      padding-left: 20px;
    }

    .value {
      align-items: center;
      display: flex;
      height: 100%;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      padding-left: 20px;
    }
  }
}

.popup {
  width: 350px;
  box-shadow: 0 5px 12px 0 rgba(217, 226, 233, 0.5);
  border: solid 1px #E9EDF4;
  background-color: #fff;
  position: absolute;

  .header {
    padding-top: 20px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .increment,
    .decrement {
      width: 48px;
      height: 48px;
      position: relative;
      border: none;
      background: none;
      cursor: pointer;
    }

    .decrement {
      background: url(../../images/before.svg) no-repeat center;
    }

    .increment {
      background: url(../../images/after.svg) no-repeat center;
    }

    .title {
      font-size: 30px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
    }
  }

  .actions {
    position: relative;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .complete {
      position: relative;
      margin-right: 20px;
      padding-right: 8.5px;
      padding-left: 8.5px;
      height: 30px;
      border: none;
      background: none;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #00e7ff;
      border-radius: 2px;
      transition: box-shadow .3s ease-in-out;

      &.isActivated {
        box-shadow: 0 0 0 2px rgba(#00e7ff, .5);
      }
    }
  }

  .body {
    width: 310px;
    height: 280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .captions {
      display: flex;

      .caption {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        display: flex;
        color: #868e96;
        font-size: 15px;
        align-items: center;
        justify-content: center;

        &.isLast {
          margin-right: 0;
        }
      }
    }

    .days {
      display: flex;
      flex-wrap: wrap;
      width: 310px;

      .day {
        position: relative;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        cursor: pointer;

        .text {
          width: 100%;
          height: 100%;
          display: flex;
          font-size: 15px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        &:hover {
          .text {
            background: rgba(#00e7ff, 0.5);
            box-shadow: 2px 2px 3px 0 rgba(39, 46, 64, 0.15);
            color: #fff;
          }
        }

        &.isToday {
          .text {
            font-weight: bold;
            color: #00e7ff;
          }
        }

        &.isNotDayInMonths {
          .text {
            color: #868e96;
          }
        }

        &.isSaturday {
          margin-right: 0;
          .text {
            color: #00e7ff;
          }
        }

        &.isSunday {
          .text {
            color: #FF5D5D;
          }
        }

        &.isSelected {
          .text {
            background: #00e7ff;
            color: #fff;
          }
        }

        &.isDisabled {
          .text {
            color: #dadfe1;
          }
        }
      }
    }
  }
}

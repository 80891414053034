.searchItemForm {
  background: #ffffff;
  padding: 40px;
  margin-bottom: 20px;
  border-radius: 4px;
  max-width: 500px;
  box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  .actions {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

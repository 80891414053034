.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #0f1011;

  .menu {
    display: flex;

    a {
      display: flex;
      width: 100%;
      align-content: center;
      font-size: 12px;
      text-decoration: none;
      color: #fff;
      padding: 14px 20px;
      letter-spacing: .5px;

      &:hover {
        background: rgba(255, 255, 255, .2);
      }
    }

    &.isSelected {
      a {
        background: #ffffff;
        color: #0f1011;
      }
    }
  }
}
.imageInput {
  width: 200px;
  height: 200px;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  cursor: pointer;

  input {
    display: none;
  }

  .label {
    color: #868e96;
  }

  .image {
    width: 100%;
    height: 100%;
    background-color: #000;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-radius: 4px;

    &.isEmpty {
      background: #efefef;
      border: solid #ddd 1px;
    }
  }
}

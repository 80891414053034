.tableRow {
  display: flex;
  text-decoration: none;
  color: #0f1011;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border-bottom: solid #efefef 1px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #fafafa;
  }

  &.isHeader {
    background: #f3f3f3;
    border-bottom: solid #ddd 2px;
  }
}
.postsPage {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .newPostButton {
      text-decoration: none;
      color: #00e7ff;
      background: #0f1011;
      font-size: 12px;
      padding: 6px 12px;
      border-radius: 4px;
      position: relative;
    }
  }
}

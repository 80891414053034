.hashTagPage {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .form {
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 40px;

    .hashTags {
      display: flex;
      flex-wrap: wrap;
    }

    .input {
      padding-top: 20px;
    }
  }
}

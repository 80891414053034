.contactsPage {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .actions {
    padding-bottom: 12px;
    display: flex;
    justify-content: flex-end;
  }

  .appBar {
    width: 100%;
    height: 50px;
    background: #ffffff;
    margin-bottom: 20px;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;

    .menu {
      text-decoration: none;
      color: #0f1011;
      font-size: 12px;
      display: flex;
      padding: 10px 20px;
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &.isSelected {
        background: #0f1011;
        color: #00e7ff;
      }
    }
  }
}

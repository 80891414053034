.checkBox {
  display: flex;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;
  user-select: none;

  .shape {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: solid #ddd 2px;
    box-sizing: border-box;
    background: #ffffff;
    position: relative;

    .icon {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

    }
  }

  .label {
    line-height: 20px;
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 8px;
  }

  &.checked {
    .shape {
      border: none;
      background: #0f1011;
      color: #fff;
    }
  }
}
.hashTagSelector {
  max-width: 500px;
  display: flex;
  flex-direction: column;

  .label {
    color: #868e96;
    margin-top: 14px;
  }

  .selectedHashTags {
    display: flex;
    flex-wrap: wrap;

    .addHashTagButton {
      display: flex;
      font-size: 14px;
      background: #0f1011;
      color: #fff;
      padding: 4px 8px;
      border-radius: 12px;
      margin-right: 4px;
      margin-bottom: 4px;
      cursor: pointer;
      appearance: none;
      border: none;
      align-items: center;

      &:hover {
        background: #404040;
      }
    }

    .empty {
      font-size: 12px;
      color: #868e96;
      padding: 2px 0;
      margin-right: 4px;
    }
  }
}

.popup {
  box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  z-index: 9999;
  width: 200px;
  height: 300px;
  background: #ffffff;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  .search {
    height: 40px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(228, 231, 235);
    background: rgb(245, 246, 247);
    box-sizing: border-box;

    input {
      font-size: 12px;
      border: none;
      background: none;
      width: 100%;
      padding: 0 20px;
    }

    & > .icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: rgb(123, 139, 154);
    }
  }

  .leftHashTags {
    overflow: auto;
    height: 260px;

    .leftHashTag {
      padding: 10px 20px;
      font-size: 12px;
      cursor: pointer;
      border-bottom: solid #efefef 1px;

      &:hover {
        background: #fafafa;
      }
    }
  }
}
.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  .label {
    font-size: 12px;
    color: #868e96;
    margin-bottom: 2px;
    line-height: 1;
  }

  .value {
    font-size: 12px;
    white-space: pre-wrap;
    line-height: 1.7;
  }
}

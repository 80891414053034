.pagination {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  padding: 20px 0;

  .container {
    display: flex;
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      min-width: 30px;
      padding: 0 4px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      align-content: center;
      align-items: center;
      justify-content: center;
      border: solid #ddd 1px;
      box-sizing: border-box;
      border-radius: 2px;
      margin: 0 4px;
      cursor: pointer;
      background: #ffffff;
      width: 100%;
    }
  }

  .active {
    a {
      background: #0f1011;
      color: #fff;
      border: none;
    }
  }
}
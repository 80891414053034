.select {
  height: 40px;
  min-width: 106px;
  border-radius: 2px;
  border: solid 1px #ccd8e8;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 12px 16px;
  cursor: pointer;
  background-image: url(../../images/select-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  position: relative;
  padding-right: 48px;
  white-space: nowrap;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out;

  &:hover {
    border-color: #aebaca;
  }

  &.isVisible {
    border-color: #333;
  }

  .selectArea {
    width: 100%;
  }

  .placeholder {
    font-size: 14px;
    line-height: 1.14;
    color: #afc2db;
  }

  .value {
    font-size: 14px;
    line-height: 1.14;
    color: #272e40;
  }
}

.options {
  max-height: 220px;
  position: absolute;
  background: #fff;
  border: solid 1px #ccd8e8;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  top: 42px;
  left: 0;
  transform: translateY(40px);
  border-radius: 2px;
  overflow: auto;
  display: flex;
  z-index: 999;
  flex-direction: column;

  .option {
    background: #ffffff;
    border: none;
    cursor: pointer;
    appearance: none;
    display: flex;
    position: relative;
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    border-bottom: solid rgba(#afc2db, .25) 1px;
    padding: 12px 16px;
    text-align: left;
    font-size: 14px;
    color: #272e40;
  }

  &.b {
    top: 50px;
  }
}
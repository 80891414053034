.filtersPage {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .newFilterButton {
      text-decoration: none;
      color: #00e7ff;
      background: #0f1011;
      font-size: 12px;
      padding: 6px 12px;
      border-radius: 4px;
      position: relative;
    }
  }

  .actions {
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;

    input {
      padding: 8px 12px;
      font-size: 14px;
      line-height: 20px;
      vertical-align: middle;
      background-repeat: no-repeat;
      border: solid #ddd 1px;
      background-position: right 8px center;
      border-radius: 6px;
      outline: none;
    }
  }
}
